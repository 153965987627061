import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useI18n } from '../../../../i18n';
import { unitsString } from '../../../misc/attributeTypes';
import NoDataView from '../../general/view/NoDataView';
import './groupMessageFieldsView.scss';

// -----------------------------

/**
 * @param {Object} props
 * @param {string} props.title  message field
 * @param {number} props.min
 * @param {number} props.max
 * @param {string} props.units
 */

function FieldsRangeView(props) {
	const { f } = useI18n();
	return (
		<div className="message-field">
			<span className="capitalize label">{f({ prefix: 'message-field', id: props.title })}: </span>
			<span>{props.min}</span>
			{props.min != props.max && <span> - {props.max}</span>}
			{props.units && <>&nbsp;<span>{f({ prefix: 'units', id: props.units })}</span></>}
		</div>
	);
}

// ---------------------------------

/**
 * @param {Object} props
 * @param {string} props.title  message field
 * @param {Array.<string>} props.values
 * @param {string} props.units
 */

function _FieldsValuesView(props) {
	const { f, p } = useI18n();
	let uniqValues = [];

	const getUniqValues = (values) => {
		const uniq = {};
		values && values.forEach(value => uniq[value] = true);
		return Object.keys(uniq);
	}

	if (props.values) {
		uniqValues = getUniqValues(props.values);
	}

	return (
		<div className="message-field">
			<span className="capitalize label">{f({ prefix: 'message-field', id: props.title })}: </span>
			{uniqValues.length == 1
				? (
					props.devicesMap && props.devicesMap[uniqValues[0]]
						? <Link to={'/dashboard/devices/' + uniqValues[0]}>{props.devicesMap[uniqValues[0]].name || uniqValues[0]}</Link>
						: <span>{uniqValues[0]}</span>
				)
				: <span>{p('X values', uniqValues.length)}</span>
			}
			{props.units && uniqValues.length == 1 && <>&nbsp;<span>{f({ prefix: 'units', id: props.units })}</span></>}
		</div>
	);
}

const FieldsValuesView = connect(state => {
	return {
		devicesMap: state.devices.map
	}
})(_FieldsValuesView);

/**
 * @param {Object} props
 * @param {Array.<cx.ods.devices.MessageDetails>} props.messages
 * @param {Array.<string>} props.uris
 */

const EXCLUDE_FIELDS = ["altitude", "longitude", "latitude", "no-gnss-fix"];
const EXCLUDE_TYPES = ["numeric", "geo-zone", "device-id", "{g}", "url"]; // Attribute
const VALUE_TYPES = ["boolean", "no", "enum", "text", "id", "device-reference"]; // Attribute
const RANGE_TYPES = [
	"deg",
	"m",
	"kph",
	"s",
	"min",
	"%",
	"deg-C",
	"l",
	"rpm",
	"gps",
	"h",
	"V",
	"km",
	"dBm",
	"lph",
	"counter",
	"ms",
	"ms-2",
	"dB",
	"kPa",
	"distance",
	"A",
	"%-LEL"
]; // Attribute

function GroupMessageFieldsView(props) {
	const { f } = useI18n();
	const fields = {};
	const generalQuantity = props.uris ? props.uris.length : 0;

	let content = null;

	// ---------------
	if (props.messages.length > 0 && props.messageFields.typeMap) {
		const types = props.messageFields.typeMap;
		props.messages.forEach(message => {
			const fieldTypeMap = message.fields && message.fields.map();
			// Fields
			if (message.fields) {
				Object.keys(fieldTypeMap).forEach(fieldId => {
					const type = types[fieldId];
					if (type && EXCLUDE_FIELDS.indexOf(type.name) < 0) {
						const aType = props.attributeTypes.codeMap && props.attributeTypes.codeMap[type.fieldType];
						if (aType && EXCLUDE_TYPES.indexOf(aType.mnemonics) < 0) {
							fields[type.description] = { ...fields[type.description] };
							if (!fields[type.description].units) {
								fields[type.description].units = unitsString(aType.mnemonics);
							}
							if (RANGE_TYPES.includes(aType.mnemonics)) {
								if (fields[type.description].min == undefined) {
									fields[type.description].min = +fieldTypeMap[fieldId];
									fields[type.description].max = +fieldTypeMap[fieldId];
									fields[type.description].count = 1;
								} else {
									if (fields[type.description].min > +fieldTypeMap[fieldId]) {
										fields[type.description].min = +fieldTypeMap[fieldId];
									} else if (fields[type.description].max < +fieldTypeMap[fieldId]) {
										fields[type.description].max = +fieldTypeMap[fieldId];
									}
									fields[type.description].count = ++fields[type.description].count;
								}
							} else if (VALUE_TYPES.includes(aType.mnemonics)) {
								fields[type.description].values
									? fields[type.description].values.push(fieldTypeMap[fieldId])
									: fields[type.description].values = [fieldTypeMap[fieldId]]
								;
							}
						}
					}
				});
			}
		});

		// Range value of fields
		const fieldsRangeContent = [];
		Object.keys(fields).sort().forEach(subject => {
			if (fields[subject].min != undefined && fields[subject].count == generalQuantity) {
				fieldsRangeContent.push(
					<FieldsRangeView
						key={subject}
						min={fields[subject].min}
						max={fields[subject].max}
						title={subject}
						units={fields[subject].units}
					/>
				);
			} else if (fields[subject].values && fields[subject].values.length == generalQuantity) {
				fieldsRangeContent.push(
					<FieldsValuesView
						key={subject}
						title={subject}
						values={fields[subject].values}
						units={fields[subject].units}
					/>
				);
			}
		});

		content = (
			<div className="group-message-fields">
				{fieldsRangeContent.length > 0
					? (<div className="fields">
						<label><span className="capitalize">{f('data summary')}</span></label>
						{fieldsRangeContent}
					</div>)
					: (<NoDataView message={f('no common data')} />)
				}
			</div>
		);
	}

	return (content);
}

export default connect(state => {
	return {
		messageFields: state.registry.messageFields,
		attributeTypes: state.registry.attributeTypes
	};
})(GroupMessageFieldsView);
