const map = {
	"boolean": "",
	"counter": "",
	"deg": "°",
	"deg-C": "°C",
	"device-id": "",
	"device-reference": "",
	"distance": "m",
	"enum": "",
	"geo-zone": "",
	"id": "",
	"kph": "km/h",
	"ms-2": "m/s\u00B2", // acceleration
	"no": "",
	"numeric": "",
	"text": "",
	"url": "",
	"{g}": "", // g-force vector
	"rpm": "r/min",
	"gps": "g/s",
	"lph": "l/h",
	"%-LEL": "% LEL"
};

/**
 * @param {string} mnemonics
 * Note: attributeType is actually RegisteredValue.
 */

function unitsString(mnemonics) {
	return map[mnemonics] != null ? map[mnemonics] : mnemonics;
}

export { unitsString };
